import './App.css';
import Applications from './components/applications/Applications';
import SearchBar from './components/searchbar/SearchBar';
import Temperatures from './components/temperatures/Temperatures';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='layout'>
          {/* <SearchBar></SearchBar> */}
          <Temperatures></Temperatures>
          <Applications></Applications>
        </div>
      </header>
    </div>
  );
}

export default App;
