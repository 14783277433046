import React, { useState, createContext, useEffect } from "react";


export interface ApplicationGroupDto {
    name: string;
    applications: ApplicationListDto[];
}

export interface ApplicationListDto {
    title: string;
    url: string;
    logo_url: string;
}

interface ApplicationsContextInterface {
    applicationsList: ApplicationGroupDto[] | null | undefined;
}


export const ApplicationsContext = createContext<ApplicationsContextInterface>({
    applicationsList: null
});

export function ApplicationsProvider(props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) {
    const [applicationsList, setApplicationsList] = useState<ApplicationGroupDto[] | null>();

    async function fetchData() {
        fetch(`/data/applications.json`)
            .then(response => response.json())
            .then(data => setApplicationsList(data));
    }

    useEffect(() => {
        if (applicationsList == null) {
            fetchData();
        }
    });

    return (
        <ApplicationsContext.Provider
            value={{ applicationsList: applicationsList }}
            {...props}
        />
    );
}
