import { ApplicationsContext, ApplicationListDto, ApplicationsProvider } from "../../Networking/ApplicationsProvider";
import Application from "./Application";
import styles from "./Applications.module.css";

export default function Applications() {

    function generateApplicationsList(applications: ApplicationListDto[] | null | undefined) {
        if (applications === undefined || applications == null) return [];
        return (
            applications?.map((application) => (
                <Application application={application} />
            )) ?? []
        );
    }

    return (
        <ApplicationsProvider>
                <ApplicationsContext.Consumer>
                {({ applicationsList }) => {
                    
                    return (
                    applicationsList?.map((applicationGroup) => { 

                    var applications = generateApplicationsList(applicationGroup.applications);
               
                    return (
                        <div className={styles.applicationsWrapper}>
                            <h2 className="header">{applicationGroup.name}</h2>
                            <div className={styles.applications}>{applications}</div>
                             </div>);
    
                    })
                        

                    );
                    }}
                </ApplicationsContext.Consumer>
        </ApplicationsProvider>
    );

}