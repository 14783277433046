import styles from './TemperatureWidget.module.css'

export default function TemperatureWidget(props: { title: string, temperature: number }) {


    return (
        <div className={styles.temperature}>
            <h2>{props.temperature}°C</h2>
            <h5>{props.title}</h5>
        </div>
    );

}