import { ApplicationListDto } from "../../Networking/ApplicationsProvider";
import styles from "./Application.module.css";

export default function Application(props: { application: ApplicationListDto }) {


    return (
        <a className={styles.application} href={props.application.url}>
            <div className={styles.appIcon}>
                <img src={props.application.logo_url}></img>
            </div>
            <div className={styles.appDetails}>
                <h5>{props.application.title}</h5>
                <span>{props.application.url}</span>
            </div>
        </a>
    );

}