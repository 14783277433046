import React, { useState, createContext, useEffect } from "react";

export interface TemperatureDto {
    outsideTemp: number;
    livingroomTemp: SensorDto;
    hallwayTemp: SensorDto;
    bedroomTemp: SensorDto;
}

export interface SensorDto {
    Time:     Date;
    AM2301:   Am2301;
    TempUnit: string;
}

export interface Am2301 {
    Temperature: number;
    Humidity:    number;
    DewPoint:    number;
}


interface TemperaturesContextInterface {
    temperatures: TemperatureDto | null | undefined;
}


export const TemperaturesContext = createContext<TemperaturesContextInterface>({
    temperatures: null
});

export function TemperaturesProvider(props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) {
    const [temperatures, setTemperatures] = useState<TemperatureDto | null>();

    async function fetchData() {
        let t = temperatures;
        if (temperatures == null) {
            t = {} as TemperatureDto;
        }

        await fetch(`http://10.2.0.3:8125/sensors/outside/temperature`)
        .then(response => response.json())
        .then(data => {
            t!.outsideTemp = Number.parseFloat(data);
        });

        await fetch(`http://10.2.0.3:8125/sensors/hallway`)
            .then(response => response.json())
            .then(data => {
                t!.hallwayTemp = data;
            });
        
           await fetch(`http://10.2.0.3:8125/sensors/bedroom`)
            .then(response => response.json())
            .then(data => {
                t!.bedroomTemp = data;
            });
        
           await fetch(`http://10.2.0.3:8125/sensors/livingroom`)
            .then(response => response.json())
            .then(data => {
                t!.livingroomTemp = data;
            });
        
            setTemperatures(t);
        }

    useEffect(() => {
        if (temperatures == null) {
            fetchData();
        }
    });

    return (
        <TemperaturesContext.Provider
            value={{ temperatures: temperatures }}
            {...props}
        />
    );
}
