import styles from "./Temperatures.module.css";
import { TemperaturesContext, TemperaturesProvider } from "../../Networking/TemperatureProvider";
import TemperatureWidget from "./TemperatureWidget";

export default function Temperatures() {
    return (
        <TemperaturesProvider>
            <div className={styles.temperaturesWrapper}>
                <TemperaturesContext.Consumer>
                    {({ temperatures }) => {
                        return <div className={styles.temperatures}>
                            <TemperatureWidget title="Outside" temperature={temperatures?.outsideTemp ?? 0}></TemperatureWidget>
                            <TemperatureWidget title="Bedroom" temperature={temperatures?.bedroomTemp?.AM2301?.Temperature ?? 0} ></TemperatureWidget>
                            <TemperatureWidget title="Hallway" temperature={temperatures?.hallwayTemp?.AM2301?.Temperature ?? 0} ></TemperatureWidget>
                            <TemperatureWidget title="Living room" temperature={temperatures?.livingroomTemp?.AM2301?.Temperature ?? 0} ></TemperatureWidget>
                        </div>;
                    }}
                </TemperaturesContext.Consumer>
            </div>
        </TemperaturesProvider>
    );

}